const endpoints = {
  apiTokenAuth: {
    default: "/v1/api-token-auth/",
  },
  forgotPassword: {
    default: "/v1/forgot_password/",
  },
  checkToken: {
    default: "/v1/check_token/",
  },
  resetPassword: {
    default: (uuid: string, token: string) =>
      `/v1/password_reset/${uuid}/${token}/`,
  },
  facilities: {
    default: "/v1/facilities/",
    detail: (id: number) => `/v1/facilities/${id}/`,
  },
  companies: {
    default: "/v1/companies/",
    detail: (id: number) => `/v1/companies/${id}/`,
  },
  devices: {
    default: "/v1/devices/",
    detail: (id: number) => `/v1/devices/${id}/`,
    getBySerialUid: (serialUid: string) => `/v1/devices/uid/${serialUid}/`,
    getChannelsBySerialUid: (serialUid: string) =>
      `/v1/devices/uid/${serialUid}/channels/`,
    getChannelsById: (id: number) => `/v1/devices/${id}/channels/`,
    types: "/v1/device_types/",
    type: (id: number) => `/v1/device_types/${id}/`,
    versions: "/v1/device_versions/",
    version: (id: number) => `/v1/device_versions/${id}/`,
    connectable_machines: (id: number) =>
      `/v1/devices/${id}/connectable_machines/`,
    deactivate: (id: number) => `/v1/devices/${id}/deactivation/`,
    move: (id: string) => `/v1/devices/${id}/move/`,
    activate: "/v1/devices/activation/",
    statuses: "/v1/device_statuses/",
    configuration: "/v1/device_configuration/",
    health: {
      machines: "/device-health/v1/machines/",
      devices: "/device-health/v1/devices/",
    },
    telemetric: "/device-telemetry/v1/devices/",
  },
  projects: {
    default: "/v1/projects/",
    detail: (id: number) => `/v1/projects/${id}/`,
  },
  users: {
    default: "/v1/users/",
    me: "/v1/users/me/",
    detail: (id: number) => `/v1/users/${id}/`,
    projects: (id: number) => `/v1/users/${id}/projects/`,
    resendInvitation: (id: number) => `/v1/users/${id}/resend_invitation/`,
  },
  machines: {
    default: "/v1/machines/",
    detail: (id: number) => `/v1/machines/${id}/`,
    categories: "/v1/machine_categories/",
    category: (id: number) => `/v1/machine_categories/${id}/`,
    subCategories: "/v1/machine_subcategories/",
    subCategory: (id: number) => `/v1/machine_subcategories/${id}/`,
    placements: (id: number) => `/v1/machines/${id}/placements/`,
    massAcknowledgeEvents: (id: number) =>
      `/v1/machines/${id}/mass_acknowledge_events/`,
    train: (id: number) => `/v1/machines/${id}/train/`,
    utilization: "/machine-utilization/v1/machines/",
    utilizationTotals: "/machine-utilization/v1/totals/",
    status: "/machine-overviews/v1/machines/",
    models: "/model-information/v1/machines/",
    attachments: (id: number) => `/v1/event_attachments/?machine=${id}`,
    deployModel: (id: number) => `/v1/machines/${id}/deploy_model/`,
    undeployModel: (id: number) => `/v1/machines/${id}/undeploy_model/`,
    recomputeResults: (id: number) => `/v1/machines/${id}/recompute/`,
    archiveModel: (id: number) => `/v1/machines/${id}/archive_model/`,
    unarchiveModel: (id: number) => `/v1/machines/${id}/unarchive_model/`,
    productionModes: (id: number) => `/v1/machines/${id}/production_modes/`,
    stopModelTraining: (id: number) => `/v1/machines/${id}/stop_training/`,
  },
  machineImages: {
    default: "/v1/machine_images/",
    detail: (id: number) => `/v1/machine_images/${id}/`,
    getThumbnail: (id: number) => `/v1/machines/${id}/thumbnail/`,
    getFull: (id: number) => `/v1/machines/${id}/image/`,
  },
  companyImages: {
    default: "/v1/company_images/",
    detail: (id: number) => `/v1/company_images/${id}/`,
    getThumbnail: (id: number) => `/v1/companies/${id}/thumbnail/`,
    getFull: (id: number) => `/v1/companies/${id}/image/`,
  },
  events: {
    default: "/v1/events/",
    detail: (id: number) => `/v1/events/${id}/`,
    types: "/v1/event_types/",
    pair: "/v1/events/pair/",
    pairDetail: (id: number) => `/v1/events/${id}/pair/`,
    alertSeverities: "/v1/alert_severities/",
    maintenanceTypes: "/v1/maintenance_types/",
    statuses: "/v1/event_statuses/",
    comments: "/v1/event_comments/",
    editComment: (id: number) => `/v1/event_comments/${id}/`,
    eventComments: (id: number) => `/v1/events/${id}/comments/`,
    attachments: (id: number) => `/v1/event_attachments/?event=${id}`,
    attachment: (id?: number) =>
      id ? `/v1/event_attachments/${id}/` : "/v1/event_attachments/",
    groupAlerts: (id: number) => `/v1/events/${id}/set_as_master/`,
  },
  placements: {
    default: "/v1/placements/",
    detail: (id: number) => `/v1/placements/${id}/`,
  },
  placementTypes: {
    default: "/v1/placement_types/",
  },
  onboardMachine: {
    default: "/v1/onboard_machine/",
  },
  labels: {
    categories: "/v1/label_categories/",
    subCategories: "/v1/label_subcategories/",
    default: "/v1/labels/",
  },
  availability: {
    default: "/v1/get_availability/",
    range: "/v1/get_availability_range/",
  },
  audio: {
    default: "/v1/get_audio/",
    upload: "/v1/audio_chunks/upload/",
  },
  roles: {
    default: "/v1/roles/",
    visible: "/v1/roles/visible/",
  },
  channels: {
    default: "/v1/channels/",
    detail: (id: number) => `/v1/channels/${id}/`,
  },
  sensorTypes: {
    default: "/v1/sensor_types/",
  },
  audioChunksInfo: {
    default: "/v1/audio_chunks/info/",
  },
  audioChunks: {
    default: "/v1/audio_chunks/",
    detail: (id: number) => `/v1/audio_chunks/${id}/`,
    file: (id: number) => `/v1/audio_chunks/${id}/file/`,
    subsample: "/v1/audio_chunks/subsample/",
  },
  recordingPlans: {
    default: "/v1/device_recording_plans/",
  },
  isoGroups: {
    default: "/v1/iso_groups/",
  },
  failure: {
    default: `/v1/failures/`,
    type: (id: number) => `/v1/failure_types/${id}/`,
    types: "/v1/failure_types/",
    eventPlacement: "/v1/event_placement_failures/",
    editEventPlacement: (id: number) => `/v1/event_placement_failures/${id}/`,
    eventFailures: (eventId: number) =>
      `/v1/events/${eventId}/placement_failures/`,
    placementFailures: (placement_type: number) =>
      `/v1/placement_types/${placement_type}/failures/`,
  },
  modelResults: {
    placements: "/model-results/v1/placements/",
    lastActivity: "/model-results/v1/model_last_activity/",
  },
  eventExport: {
    default: "/event-export/v1/export/",
  },
  audioChunkTypes: {
    default: "/v1/audio_chunk_types/",
  },
  channelTypes: {
    default: "/v1/channel_types/",
  },
  currencies: {
    default: "/v1/currencies/",
  },
  faults: {
    default: "/v1/faults/",
    editFaults: (id: number) => `/v1/faults/${id}/`,
    deleteFault: (id: number) => `/v1/faults/${id}/`,
  },
  reports: {
    default: "/v1/monthly_reports/",
    detail: (id: number) => `/v1/monthly_reports/${id}/`,
    generate: (id: number) => `/v1/monthly_reports/${id}/generate/`,
    send: (id: number) => `/v1/monthly_reports/${id}/send/`,
  },
  temporaryToken: {
    default: "/v1/temporary_access_tokens/",
    detail: (id: number) => `/v1/temporary_access_tokens/${id}/`,
  },
  eventSounds: {
    default: "/event_sounds/v1/event_sounds",
    similaritySounds: "/similarity_search/v1/similarity_search",
    dataset: "/datasets/v1/datasets",
    datasetGroup: "/dataset_groups/v1/dataset_groups",
    datasetGroupDetail: (id: number) =>
      `/dataset_groups/v1/dataset_groups/${id}`,
    datasetDetail: (id: number) => `/datasets/v1/datasets/${id}`,
    trainDatasetGroup: "/v1/train_dataset_group/",
    customClassifierModelCategoryInfo: (category: string) =>
      `/v1/get_custom_classifier_model_category_info/?model_category=${category}`,
  },
  serviceManager: {
    default: "/services/v1/services/",
    machines: "/services/v1/machines/",
  },
  customModelInfo: {
    default: "/model-information/v1/custom_classifiers/",
    machines: (id: number) =>
      `model-information/v1/custom_classifiers/?machine=${id}`,
  },
};

export default endpoints;
