import { PureComponent } from "react";
import { enqueueSnackbar } from "notistack";
import Wave from "./Wave";
import ZoomOutView from "./ZoomOutView";
import "./Player.css";
import getPlayer from "components/dataLabeling/audio/getPlayer";
import { addLabel } from "api/handlers/dataLabeling/addLabel";
import { removeRegion } from "api/handlers/dataLabeling/removeRegion";
import { updateLabelPosition } from "api/handlers/dataLabeling/updateLabelPosition";
import * as utils from "./utils";
import { labelProcessing } from "store/player/actions";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { connect } from "react-redux";
import debounce from "debounce";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LoopIcon from "@mui/icons-material/Loop";
import { withStyles, createStyles } from "@mui/styles";
import Header from "components/dataLabeling/placementName";
import { EventModal } from "components/events";
import { EModalTypes } from "store/eventModal/types";
import { actions } from "store/eventModal/slice";
import { ELabelDateType } from "store/eventModal/types";
import moment from "moment";
import { ThemedSlider } from "./Slider";
import analysisTimeManagerActions from "store/machineDetail/analysisTimeManager/actions";
import eventSoundsActions from "store/eventSounds/actions";
import { v4 as uuidv4 } from "uuid";
import urls from "shared/appUrls";

const { setData: setAnalysisTimeManagerData } = analysisTimeManagerActions;
const { setSearchParams } = eventSoundsActions;

const MAX_ZOOM = 20;
const MIN_DB = 0;
const MAX_DB = 40;
const STEP_DB = 5;
const MOUSE_SELECTION_ZOOM = "mouse-selection-zoom";
const MOUSE_SELECTION_TAG = "mouse-selection-tag";

window.roundToString = utils.roundToString;

class PlayerComponent extends PureComponent {
  _player = null; // TODO: fix flow

  temporarilyPaused = false;
  // navigate = useNavigate();

  constructor(props) {
    super(props);

    this.state = {
      isPlaying: false,
      loop: false,
      aboveZoomOut: false,
      playSelectedRegion: false,

      // Buffer
      buffer: props.buffer,
      offsets: props.offsets,
      subOffsets: props.subOffsets,
      startDatetimes: props.startDatetimes,
      showStartDatetimes: props.showStartDatetimes,
      bufferStart: props.bufferStart,
      bufferEnd: props.bufferEnd,
      selectionDates: props.selectionDates,
      volume: props.volume,

      // Regions & labels
      regions: props.regions,
      selectedRegion: null,
      allLabels: props.allLabels,

      windowStart: props.bufferStart,
      windowEnd:
        props.bufferStart + (props.bufferEnd - props.bufferStart) / props.zoom,
      progress: 0,

      playerLeftX: null,
      playerTopY: null,
      playerHeight: null,
      playerWidth: null,

      zoomOutLeftX: null,
      zoomOutTopY: null,
      zoomOutHeight: null,
      zoomOutWidth: null,

      mouseSelection: MOUSE_SELECTION_TAG,
      selectionStart: null,
      selectionEnd: null,
      duration: props.duration || 180,

      createEvent: false,
      addEventModal: false,
      modelResults: null,
      addDate: false,

      preciseActive: false,
      preciseRegions: {},
      preciseShown: false,
      onPreciseSelection: (preciseRegions) => {},
    };
  }

  render() {
    const {
      isPlaying,
      loop,
      offsets,
      subOffsets,
      startDatetimes,
      showStartDatetimes,
      selectionDates,
      bufferStart,
      regions,
      selectedRegion,
      allLabels,
      progress,
      windowStart,
      windowEnd,
      mouseSelection,
      selectionStart,
      selectionEnd,
      aboveZoomOut,
      createEvent,
      addEventModal,
      addDate,
      preciseActive,
      preciseRegions,
      preciseShown,
    } = this.state;

    const {
      classes,
      placement,
      labelPlacements,
      renderFFT,
      duration,
      machine,
      zoomOutOffset,
      zoomOutLoading,
      loadBufferSilent,
      timezoneOffset,
      t,
      hideZoomOutView,
      drawBothWaveAndSpectrogram,
      turnOffEvents,
      downloadCallback,
      data,
      modelResults,
      volume,
      playerActions,
      dispatch,
      buffer,
      allowPrecise,
      onPreciseSelection,
      labelsFilterData,
      setQuery,
    } = this.props;

    if (isPlaying && this.temporarilyPaused) {
      this.temporarilyPaused = false;
      this.play();
    }

    const setVolume = (e, volume) =>
      this.setState((state) => ({ ...state, volume }));

    const setCommittedVolume = (e, committedVolume) => {
      if (volume === committedVolume) return;
      this.temporarilyPaused = isPlaying;
      this.pause();
      dispatch(playerActions.setVolume(placement.value, committedVolume));
    };

    const marginLeftAutoFirst = (() => {
      var called = false;
      return () => {
        const ret = !called;
        called = true;
        return ret ? { marginLeft: "auto" } : {};
      };
    })();

    return buffer ? (
      <>
        <Box display="flex" flexWrap="wrap" mb={1} className="supervisedArea">
          <Button
            id={`testTaggingAppPlay${placement.value}`}
            className={classes.button}
            data-cy="playButton"
            variant="contained"
            onClick={this.onTogglePlay}
          >
            {isPlaying
              ? t("taggingapp.button.pause")
              : t("taggingapp.button.play")}
          </Button>
          <div
            style={{
              width: "8rem",
              marginLeft: "1rem",
              marginRight: "0.5rem",
              textAlign: "center",
              lineHeight: 0.5,
            }}
          >
            <ThemedSlider
              value={this.state.volume}
              step={STEP_DB}
              min={MIN_DB}
              max={MAX_DB}
              onChange={setVolume}
              onChangeCommitted={setCommittedVolume}
              marks={true}
            />
            {`+ ${this.state.volume} dB`}
          </div>
          <IconButton
            color={loop ? "secondary" : "primary"}
            className={classes.iconButton + " " + (loop ? classes.loop : "")}
            onClick={() =>
              this.setState((state) => ({
                loop: !state.loop,
              }))
            }
            style={{ marginLeft: 10 }}
          >
            <LoopIcon />
          </IconButton>
          <Box ml={2}>
            <Header placement={placement} />
          </Box>
          {showStartDatetimes && (
            <div style={{ paddingLeft: 20 }}>
              {t("analysisTimeManager.showingNSamples", {
                n: startDatetimes.length,
              })}
            </div>
          )}
          {showStartDatetimes && (
            <Button
              className={classes.button}
              onClick={() =>
                this.setState((old) => ({
                  addDate: !old.addDate,
                }))
              }
              style={{ ...marginLeftAutoFirst(), marginRight: 8 }}
            >
              {addDate
                ? t("taggingapp.button.cancelAddDate")
                : t("taggingapp.button.addDate")}
            </Button>
          )}
          {showStartDatetimes && (
            <Button
              className={classes.button}
              onClick={() =>
                this.setState((old) => ({
                  createEvent: !old.createEvent,
                }))
              }
              style={{ marginRight: 8 }}
            >
              {createEvent
                ? t("taggingapp.button.cancelCreateEvent")
                : t("taggingapp.button.createEvent")}
            </Button>
          )}
          {allowPrecise && !!Object.keys(preciseRegions).length && (
            <Button
              id={`tagginAppPreciseToggle${placement.value}`}
              className={classes.button}
              variant="contained"
              onClick={() => {
                this.setState((old) => {
                  onPreciseSelection(old.preciseShown ? {} : preciseRegions);
                  return { preciseShown: !old.preciseShown };
                });
              }}
              style={{ ...marginLeftAutoFirst(), marginRight: 8 }}
            >
              {preciseShown
                ? t("taggingapp.button.hidePrecise")
                : t("taggingapp.button.showPrecise")}
            </Button>
          )}
          {allowPrecise && (
            <Button
              id={`tagginAppPrecise${placement.value}`}
              className={classes.button}
              variant="contained"
              onClick={() =>
                this.setState((old) => ({
                  preciseActive: !old.preciseActive,
                  preciseRegions: {},
                  preciseShown: false,
                }))
              }
              style={{ ...marginLeftAutoFirst(), marginRight: 8 }}
            >
              {preciseActive
                ? t("taggingapp.button.cancelPrecise")
                : t("taggingapp.button.precise")}
            </Button>
          )}
          {downloadCallback && (
            <Button
              id={`testTaggingAppDownload${placement.value}`}
              className={classes.button}
              variant="contained"
              onClick={downloadCallback}
              style={{ ...marginLeftAutoFirst() }}
            >
              {t("taggingapp.button.download")}
            </Button>
          )}
        </Box>
        <Box id={`supervisedArea-${placement.value}`}>
          {!hideZoomOutView && (
            <ZoomOutView
              windowStart={this.props.windowStart}
              windowEnd={this.props.windowStart + 3600}
              selectionStart={selectionStart}
              selectionEnd={selectionEnd}
              machine={machine}
              placement={placement}
              margin={5}
              timezoneOffset={timezoneOffset}
              canvasHeight={70}
              loadBufferSilent={loadBufferSilent}
              setBufferOffset={this.setZoomOutOffset}
              offset={zoomOutOffset}
              regions={regions}
              selectedRegion={selectedRegion}
              onResizeZoomOut={this.onResizeZoomOut}
              onSelectRegion={this.onSelectRegion}
              onDragRegion={this.onDragRegion}
              aboveZoomOut={aboveZoomOut}
            />
          )}
          {!zoomOutLoading ? (
            <Wave
              buffer={buffer}
              offsets={offsets}
              subOffsets={subOffsets}
              startDatetimes={startDatetimes}
              showStartDatetimes={showStartDatetimes}
              selectionDates={selectionDates}
              bufferStart={bufferStart}
              regions={regions}
              selectedRegion={selectedRegion}
              placement={placement}
              labelPlacements={labelPlacements}
              allLabels={allLabels}
              windowStart={windowStart}
              windowEnd={windowEnd}
              progress={progress}
              mouseSelection={mouseSelection}
              selectionStart={selectionStart}
              selectionEnd={selectionEnd}
              renderFFT={renderFFT}
              onResize={this.onResize}
              onChangeWindow={this.onChangeWindow}
              onSelectRegion={this.onSelectRegion}
              onChangeLabel={this.onChangeLabel}
              onRemoveAllLabels={this.onRemoveAllLabels}
              onCopyLink={this.onCopyLink}
              onSearch={this.onSearch}
              onDragRegion={this.onDragRegion}
              onResizeRegion={this.onResizeRegion}
              duration={duration}
              loop={loop}
              aboveZoomOut={aboveZoomOut}
              t={t}
              drawBothWaveAndSpectrogram={drawBothWaveAndSpectrogram}
              turnOffEvents={turnOffEvents}
              data={data}
              modelResults={modelResults}
              preciseRegions={preciseRegions}
              labelsFilterData={labelsFilterData}
              onChangeProgress={this.onChangeProgress}
              onPlay={this.onTogglePlayLabel}
              updateRegion={this.updateRegion}
              rawRegions={this.props.regions}
              setQuery={setQuery}
            />
          ) : (
            <Grid container justifyContent="center" alignItems="center">
              <CircularProgress />
            </Grid>
          )}
        </Box>
        {addEventModal && (
          <EventModal
            open={true}
            machineId={machine}
            modalType={EModalTypes.create}
            onCancel={() => this.setState({ addEventModal: false })}
          />
        )}
      </>
    ) : (
      <span>Loading...</span>
    );
  }

  statePlay = () => {
    this.props.setPlaying();
    this.play();
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.isPlaying === true &&
      this.state.loop === true &&
      this.state.progress === this.props.bufferStart
    ) {
      setTimeout(() => {
        this.play();
      }, 1000);
    }
    if (
      prevState.windowStart !== this.state.windowStart ||
      prevState.windowEnd !== this.state.windowEnd
    ) {
      this.setState({ ...this.state, progress: this.state.windowStart });
    }
    if (
      prevState.loop !== this.state.loop ||
      (this.state.loop &&
        prevState.selectedRegion !== this.state.selectedRegion)
    ) {
      this.pause();
      if (prevState.isPlaying) {
        this.play();
      }
    }

    if (prevProps.isPlaying !== this.props.isPlaying) {
      this.setState(() => ({
        isPlaying: this.props.isPlaying === this.props.placement.value,
      }));
    }

    if (prevProps.localZoom !== this.props.localZoom) {
      this.onChangeWindow(
        this.props.windowStart,
        this.props.bufferStart +
          (this.props.bufferEnd - this.props.bufferStart) / this.props.localZoom
      );
    }

    if (this.state.playSelectedRegion) {
      if (this.state.progress >= this.state.selectedRegion?.end) {
        this.pause();
        this.setState({
          ...this.state,
          isPlaying: false,
        });
      }
    }
  }

  componentDidMount() {
    this._player = getPlayer(this.props.sampleRate);
    const area = document.querySelector(
      `#supervisedArea-${this.props.placement.value}`
    );
    area.addEventListener("mousedown", this.onMouseDown);
    area.addEventListener("mousemove", this.onMouseMove);
    area.addEventListener("mouseup", this.onMouseUp);
  }

  componentWillUnmount() {
    const area = document.querySelector(
      `#supervisedArea-${this.props.placement.value}`
    );

    area.removeEventListener("mousedown", this.onMouseDown);
    area.removeEventListener("mousemove", this.onMouseMove);
    area.removeEventListener("mouseup", this.onMouseUp);
    this.pause();
    this._player.release();
  }

  play() {
    if (this._player && this.props.buffer) {
      let {
        bufferStart,
        progress,
        windowStart,
        windowEnd,
        loop,
        selectedRegion,
      } = this.state;
      const { buffer } = this.props;
      let loopStart;
      let loopEnd;
      if (windowEnd - progress < 0.2) {
        progress = windowStart;
      }
      if (loop && selectedRegion) {
        loopStart = selectedRegion.start - bufferStart;
        loopEnd = selectedRegion.end - bufferStart;
      }
      let offset = Math.max(0, progress - bufferStart);
      let duration = windowEnd - progress;
      this._player.play(
        buffer,
        loopStart || offset,
        duration,
        (progress) => {
          if (this.state.isPlaying) {
            this.setState({ ...this.state, progress: bufferStart + progress });
          }
        },
        () => {
          if (this.state.loop === true) {
            this.setState({
              ...this.state,
              isPlaying: true,
              progress: this.props.bufferStart,
            });
          } else {
            this.setState({
              ...this.state,
              isPlaying: false,
            });
          }
        },
        loopStart,
        loopEnd
      );
    }
  }

  pause() {
    if (this._player) {
      this._player.stop();
    }
  }

  onTogglePlay = () => {
    let { isPlaying } = this.state;
    if (isPlaying) {
      this.pause();
    } else {
      this.statePlay();
    }
    this.setState({ ...this.state, isPlaying: !isPlaying });
  };

  onTogglePlayLabel = () => {
    let { isPlaying, selectedRegion, playSelectedRegion, windowStart } =
      this.state;
    this.onChangeProgress(selectedRegion.start);
    if (isPlaying) {
      this.statePlay();
    }

    this.setState({
      ...this.state,
      progress: selectedRegion.start,
      isPlaying: true,
      playSelectedRegion: true,
    });

    if (playSelectedRegion) {
      this.setState({
        ...this.state,
        progress: windowStart,
        isPlaying: true,
        playSelectedRegion: false,
      });
    }
  };

  updateRegion = (regions) => {
    this.setState({ ...this.state, regions });
  };

  onDragRegion = (dragStart, dragEnd, zoomOut) => {
    let { playerLeftX, selectedRegion, playerWidth, zoomOutWidth } = this.state;
    let { windowStart, timezoneOffset } = this.props;
    let regions = this.state.regions.slice();
    if (dragEnd < playerLeftX) {
      dragEnd = playerLeftX;
    } else if (dragEnd > playerLeftX + playerWidth) {
      dragEnd = playerLeftX + playerWidth;
    }

    const pxPerSec = zoomOut
      ? utils.getPxPerSec(
          { windowStart, windowEnd: windowStart + 3600 },
          zoomOutWidth || 0
        )
      : utils.getPxPerSec(this.state, playerWidth || 0);
    const dragDistance = (dragEnd - dragStart) / pxPerSec;

    let { start, end, ids, labels } = selectedRegion;
    let indexOfRegion = this.state.regions.indexOf(selectedRegion);
    if (start && end) {
      start += dragDistance;
      end += dragDistance;
    }

    regions[indexOfRegion] = { start, end, labels, ids };
    this.setState((prevState) => ({
      ...prevState,
      regions,
      selectedRegion: { start, end, labels, ids },
    }));

    ids.map((id) => updateLabelPosition(id, start, end, timezoneOffset));
  };

  onResizeRegion = (region, side, resizeStart, e) => {
    let { playerWidth } = this.state;
    const { timezoneOffset } = this.props;
    let regions = this.state.regions.slice();
    let { start, end, labels, ids } = region;
    let indexOfRegion = this.state.regions.indexOf(region);
    let resizeDistance =
      (e.pageX - resizeStart) / utils.getPxPerSec(this.state, playerWidth || 0);
    if (side === 0) {
      start += resizeDistance;
    } else if (side === 1) {
      end += resizeDistance;
    }

    if (start < end) {
      regions[indexOfRegion] = { start, end, labels, ids };
    } else if (start > end && side === 0) {
      start = end - 6;
      regions[indexOfRegion] = { start, end, labels, ids };
    } else if (start > end && side === 1) {
      end = start + 6;
      regions[indexOfRegion] = { start, end, labels, ids };
    }
    this.setState(
      {
        ...this.state,
        regions,
        selectedRegion: { start, end, labels, ids },
      },
      () => {
        ids.map((id) => updateLabelPosition(id, start, end, timezoneOffset));
      }
    );
  };

  triggerSnackbar = (message) => {
    const { t } = this.props;
    enqueueSnackbar(t(message), {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
    });
  };

  onSelectRegion = (region, e) => {
    let { selectionStart, selectionEnd } = this.state;
    let start = Math.min(selectionStart || 0, selectionEnd || 0);
    let end = Math.max(selectionStart || 0, selectionEnd || 0);

    if (end - start < 1 || selectionEnd === null) {
      this.setState({
        ...this.state,
        selectedRegion: region,
        selectionStart: null,
        selectionEnd: null,
      });
    } else {
      this.onMouseUp(e);
    }
  };

  onChangeLabel = async (item, checked, placement, id) => {
    const { selectedRegion, regions, allLabels } = this.state;
    const { timezoneOffset, labelProcessing } = this.props;
    labelProcessing && labelProcessing(true);
    if (selectedRegion !== null) {
      let newSelectedRegion = {
        ...selectedRegion,
        labels: [...selectedRegion.labels],
      };

      let { labels } = newSelectedRegion;
      try {
        if (!checked && id) {
          await removeRegion(id, timezoneOffset);
          newSelectedRegion.labels = labels.filter(({ name }) => name !== item);
          this.triggerSnackbar("taggingapp.labels.remove.success");
        } else {
          const { id, subcategory } = await addLabel(
            allLabels.filter(({ name }) => name === item)[0],
            selectedRegion.start,
            selectedRegion.end,
            [placement.value],
            timezoneOffset
          );
          newSelectedRegion.labels = [
            ...labels,
            { name: item, id, subcategory },
          ];
          this.triggerSnackbar("taggingapp.labels.add.success");
        }
      } catch {
        return checked
          ? this.triggerSnackbar("taggingapp.labels.remove.fail")
          : this.triggerSnackbar("taggingapp.labels.add.fail");
      }
      let newRegions = regions.map((r) => {
        if (r.start + r.end === selectedRegion.start + selectedRegion.end) {
          return newSelectedRegion;
        } else {
          return r;
        }
      });

      this.setState({
        ...this.state,
        regions: newRegions,
        selectedRegion: newSelectedRegion,
        allLabels: [...this.state.allLabels, { name: item, id }], // TODO: this is hack
      });
      labelProcessing && labelProcessing(false);
    }
  };

  onRemoveAllLabels = async () => {
    let { selectedRegion } = this.state;
    const { timezoneOffset } = this.props;

    if (selectedRegion !== null) {
      await Promise.all(
        selectedRegion.labels
          .filter(({ name }) => name[0] !== "$")
          .map(async ({ id }) => await removeRegion(id, timezoneOffset))
      );
      if (
        selectedRegion.labels.filter(({ name }) => name[0] === "$").length === 0
      ) {
        this.setState({
          ...this.state,
          selectedRegion: null,
          regions: this.state.regions.filter(
            (region) =>
              region.start + region.end !==
              selectedRegion.start + selectedRegion.end
          ),
        });
      }
    }
  };

  onCopyLink = () => {
    let { selectedRegion } = this.state;
    const { placement, machine } = this.props;
    if (selectedRegion !== null) {
      const href = new URL(window.location);
      href.searchParams.set("start", (selectedRegion.start * 1000).toString());
      href.searchParams.set("end", (selectedRegion.end * 1000).toString());
      href.searchParams.set("machine", machine.toString());
      href.searchParams.set("placement", placement.value.toString());
      navigator.clipboard.writeText(href.toString());
      this.triggerSnackbar("taggingapp.labels.copyLink.success");
    }
  };

  onSearch = () => {
    let { selectedRegion } = this.state;
    const { placement, dispatch } = this.props;

    if (selectedRegion !== null) {
      const pathName = urls.eventSounds.detailSimilaritySearch();
      const placementId = placement.value;
      const startDate = moment
        .unix(selectedRegion.start)
        .format("YYYY-MM-DD HH:mm:ss.SSS");
      const endDate = moment
        .unix(selectedRegion.end)
        .format("YYYY-MM-DD HH:mm:ss.SSS");

      const start = moment(startDate).toDate();
      const end = moment(endDate).toDate();

      dispatch(
        setSearchParams({
          from: start,
          to: end,
          placement: placementId,
        })
      );

      const url = `${pathName}?from=${start.toISOString()}&placement=${placementId}&to=${end.toISOString()}`;

      window.open(url, "_blank");
    }
  };

  onChangeProgress = (progress) => {
    this.setState(
      {
        ...this.state,
        progress,
        selectionStart: null,
        selectionEnd: null,
      },
      () => {
        this.pause();
        if (this.state.isPlaying) {
          this.play();
        }
      }
    );
  };

  onResize = (rect) => {
    this.setState({
      playerLeftX: rect.left,
      playerTopY: rect.top,
      playerWidth: rect.width,
      playerHeight: rect.height,
    });
  };

  onResizeZoomOut = (rect) => {
    this.setState({
      zoomOutLeftX: rect.left,
      zoomOutTopY: rect.top,
      zoomOutWidth: rect.width,
      zoomOutHeight: rect.height,
    });
  };

  onChangeWindow = (windowStart, windowEnd) => {
    let prevZoom = this.props.buffer !== null ? utils.getZoom(this.state) : 0;
    this.setState(
      {
        ...this.state,
        isPlaying: false,
        windowStart,
        windowEnd,
        selectionStart: null,
        selectionEnd: null,
      },
      () => {
        this.pause();
        let nextZoom =
          this.props.buffer !== null ? utils.getZoom(this.state) : 0;
        if (nextZoom !== prevZoom && this.props.onChangeZoom) {
          // this.props.onChangeZoom(nextZoom);
        }
      }
    );
  };

  abovePlayer(e, f) {
    let { playerLeftX, playerTopY, playerHeight, playerWidth } = this.state;
    const graphsCount =
      1 +
      Number(!!this.props.drawBothWaveAndSpectrogram) +
      Number(!!this.props.modelResults);
    if (
      playerLeftX !== null &&
      playerTopY !== null &&
      playerHeight !== null &&
      playerWidth !== null &&
      utils.between(e.pageX, playerLeftX, playerLeftX + playerWidth) &&
      utils.between(
        e.pageY,
        playerTopY,
        playerTopY + playerHeight * graphsCount
      )
    ) {
      this.setState({ aboveZoomOut: false }, () => {
        f();
      });
    }
  }
  aboveZoomOut(e, f) {
    const { zoomOutLeftX, zoomOutTopY, zoomOutHeight, zoomOutWidth } =
      this.state;
    if (
      zoomOutLeftX !== null &&
      zoomOutTopY !== null &&
      zoomOutHeight !== null &&
      zoomOutWidth !== null &&
      utils.between(e.pageX, zoomOutLeftX, zoomOutLeftX + zoomOutWidth) &&
      utils.between(e.pageY, zoomOutTopY, zoomOutTopY + zoomOutHeight)
    ) {
      this.setState({ aboveZoomOut: true }, () => {
        f();
      });
    }
  }

  onMouseWheel = (e) => {
    if (this.props.isDocumentEventActive) {
      return;
    }
    this.abovePlayer(e, () => {
      let normalized = utils.normalizeWheel(e);
      this.changeZoom(-normalized.pixelY);
      e.stopPropagation();
      e.stopImmediatePropagation();
    });
  };

  changeZoom = debounce((distance) => {
    let zoomSpeed = 70;
    let prevZoom = this.props.buffer !== null ? utils.getZoom(this.state) : 0;
    let prevZoomTotal = prevZoom * zoomSpeed;
    let zoom = (prevZoomTotal + distance) / zoomSpeed;
    if (zoom < 1) {
      zoom = 1;
    } else if (zoom > MAX_ZOOM) {
      zoom = MAX_ZOOM;
    }

    let { buffer, bufferStart, windowStart, windowEnd } = this.state;
    if (buffer) {
      let half = (windowEnd - windowStart) / 2;
      let middle = windowStart + half;
      let newHalf = (half * prevZoom) / zoom;
      let newWindowStart = Math.max(middle - newHalf, bufferStart);
      let newWindowEnd =
        newWindowStart + Math.min(2 * newHalf, buffer.duration);
      if (newWindowEnd > bufferStart + buffer.duration) {
        newWindowEnd = bufferStart + buffer.duration;
        newWindowStart = Math.max(newWindowEnd - 2 * newHalf, bufferStart);
      }

      this.onChangeWindow(newWindowStart, newWindowEnd);
    }
  }, 0);

  onMouseDown = (e) => {
    if (this.props.isDocumentEventActive || this.state.addDate) {
      return;
    }
    const { playerLeftX, zoomOutLeftX } = this.state;
    if (
      !this.props.drawBothWaveAndSpectrogram ||
      this.state.createEvent ||
      this.state.preciseActive ||
      this.props.addDateActive
    ) {
      this.abovePlayer(e, () => {
        if (playerLeftX !== null) {
          this.setState({
            ...this.state,
            selectionStart: e.pageX - playerLeftX,
          });
        }
      });
    }

    this.aboveZoomOut(e, () => {
      if (zoomOutLeftX !== null) {
        this.setState({
          ...this.state,
          selectionStart: e.pageX - zoomOutLeftX,
        });
      }
    });
  };

  onMouseMove = (e) => {
    if (this.state.selectionStart === null || this.state.addDate) {
      return;
    }

    let {
      playerLeftX,
      playerWidth,
      zoomOutLeftX,
      zoomOutWidth,
      selectionStart,
      preciseActive,
    } = this.state;

    const windowStart = utils.calculateSelectionPoint(
      this.state,
      selectionStart,
      utils.getPxPerSec(this.state, playerWidth || 0)
    );

    if (playerLeftX !== null && playerWidth !== null) {
      if (Math.abs(e.pageX - playerLeftX - selectionStart) > 14) {
        let selectionEnd = Math.min(
          Math.max(0, e.pageX - playerLeftX),
          playerWidth
        );
        if (preciseActive) {
          selectionEnd = Math.min(
            Math.max(selectionStart, selectionEnd),
            utils.calculatePositionPoint(
              this.state,
              this.findMaxPositionInSameOffset(windowStart),
              utils.getPxPerSec(this.state, playerWidth || 0)
            )
          );
        }
        this.setState({ ...this.state, selectionEnd });
      }
    }

    if (zoomOutLeftX !== null && zoomOutWidth !== null) {
      if (Math.abs(e.pageX - zoomOutLeftX - selectionStart) > 14) {
        let selectionEnd = Math.min(
          Math.max(0, e.pageX - zoomOutLeftX),
          zoomOutWidth
        );
        if (preciseActive) {
          selectionEnd = Math.min(
            Math.max(selectionStart, selectionEnd),
            utils.calculatePositionPoint(
              this.state,
              this.findMaxPositionInSameOffset(windowStart),
              utils.getPxPerSec(this.state, playerWidth || 0)
            )
          );
        }
        this.setState({ ...this.state, selectionEnd: selectionEnd });
      }
    }
  };

  onMouseUp = (e) => {
    let {
      playerLeftX,
      selectionStart,
      selectionEnd,
      playerWidth,
      zoomOutWidth,
    } = this.state;
    let start = Math.min(selectionStart || 0, selectionEnd || 0);
    let end = Math.max(selectionStart || 0, selectionEnd || 0);

    if (this.props.addDateActive) {
      let selectionEnd = Math.min(
        Math.max(0, e.pageX - playerLeftX),
        playerWidth
      );
      const { windowEnd } = utils.calculateSelectionWindow(
        this.state,
        selectionEnd,
        selectionEnd,
        utils.getPxPerSec(this.state, playerWidth || 0)
      );
      const datetime = moment.unix(windowEnd).format("YYYY-MM-DD HH:mm:ss.SSS");

      const dataToReturn = () => {
        if (this.props.analysisTimeManagerData) {
          return {
            ...this.props.analysisTimeManagerData,
            dates: [
              ...this.props.analysisTimeManagerData.dates,
              {
                date: moment(datetime).toISOString(),
                uuid: uuidv4(),
              },
            ],
          };
        } else {
          return {
            placement: this.props.placement.value,
            samples: 5,
            dates: [
              {
                date: moment(datetime).toISOString(),
                uuid: uuidv4(),
              },
            ],
          };
        }
      };

      this.props.dispatch(setAnalysisTimeManagerData(dataToReturn()));

      this.setState({
        selectionStart: null,
        selectionEnd: null,
      });

      return;
    }
    if (this.state.addDate) {
      let selectionEnd = Math.min(
        Math.max(0, e.pageX - playerLeftX),
        playerWidth
      );
      const { windowEnd } = utils.calculateSelectionWindow(
        this.state,
        selectionEnd,
        selectionEnd,
        utils.getPxPerSec(this.state, playerWidth || 0)
      );
      const datetime = this.findDatetime(windowEnd);
      this.props.dispatch(
        setAnalysisTimeManagerData({
          ...this.props.analysisTimeManagerData,
          dates: [
            ...this.props.analysisTimeManagerData.dates,
            {
              date: moment(datetime).utc().format(),
              uuid: uuidv4(),
            },
          ],
        })
      );
      this.setState({ addDate: null });
      return;
    }

    if (this.props.isDocumentEventActive) {
      return;
    }

    if (end - start < 1 || selectionEnd === null) {
      this.abovePlayer(e, () => {
        let { windowStart, playerLeftX, playerWidth, selectedRegion } =
          this.state;
        if (playerLeftX !== null && playerWidth !== null) {
          let x = e.pageX - playerLeftX;

          let progress =
            windowStart + x / utils.getPxPerSec(this.state, playerWidth);
          this.onChangeProgress(progress);
        }
      });

      this.aboveZoomOut(e, () => {
        const { zoomOutWidth, zoomOutLeftX } = this.state;

        const { windowStart } = this.props;
        if (zoomOutLeftX !== null && zoomOutWidth !== null) {
          const x = e.clientX;
          const pxPerSec = utils.getPxPerSec(
            { windowStart, windowEnd: windowStart + 3600 },
            zoomOutWidth
          );
          this.setState({ selectionStart: null }, () => {
            this.props.loadBufferSilent(
              windowStart,
              Math.floor((x - zoomOutLeftX) / pxPerSec)
            );
          });
        }
      });
    } else {
      this.abovePlayer(e, () => {
        const pxPerSec = utils.getPxPerSec(this.state, playerWidth || 0);
        const { windowStart, windowEnd } = utils.calculateSelectionWindow(
          this.state,
          start,
          end,
          pxPerSec
        );

        if (this.state.createEvent || this.state.preciseActive) {
          const { setTime, dispatch } = this.props;
          const startDatetime = this.findDatetime(windowStart);
          const endDatetime = this.findDatetime(windowEnd);
          if (this.state.createEvent) {
            setTime &&
              dispatch(
                setTime({
                  type: ELabelDateType.start,
                  time: moment(startDatetime).format(),
                })
              );
            setTime &&
              dispatch(
                setTime({
                  type: ELabelDateType.end,
                  time: moment(endDatetime).format(),
                })
              );
            this.setState({
              selectionStart: null,
              selectionEnd: null,
              createEvent: false,
              addEventModal: true,
            });
          } else {
            this.setState((prev) => {
              const newPreciseRegions = {
                ...prev.preciseRegions,
                [this.findOffsetIndex(windowStart).toString()]: {
                  start: windowStart,
                  end: windowEnd,
                },
              };
              if (this.state.preciseShown) {
                this.props.onPreciseSelection(newPreciseRegions);
              }
              return {
                selectionStart: null,
                selectionEnd: null,
                preciseRegions: newPreciseRegions,
              };
            });
          }
          return;
        }

        if (this.state.mouseSelection === MOUSE_SELECTION_ZOOM) {
          let { buffer } = this.state;
          if (buffer !== null) {
            let selectionZoom = utils.getZoom({
              buffer,
              windowStart,
              windowEnd,
            });
            if (selectionZoom <= MAX_ZOOM) {
              this.onChangeWindow(windowStart, windowEnd);
            } else {
              this.setState({
                ...this.state,
                selectionStart: null,
                selectionEnd: null,
              });
            }
          }
        } else {
          let selectedRegion = {
            start: windowStart,
            end: windowEnd,
            labels: [],
            ids: [],
          };

          let regions = [...this.state.regions, selectedRegion];
          this.setState({
            ...this.state,
            selectionStart: null,
            selectionEnd: null,
            regions,
            selectedRegion,
          });
        }
      });

      this.aboveZoomOut(e, () => {
        const { windowStart: propsWindowStart } = this.props;
        const { windowStart, windowEnd } = utils.calculateSelectionWindow(
          this.props,
          start,
          end,
          utils.getPxPerSec(
            {
              windowStart: propsWindowStart,
              windowEnd: propsWindowStart + 3600,
            },
            zoomOutWidth || 0
          )
        );

        let selectedRegion = {
          start: windowStart,
          end: windowEnd,
          labels: [],
          ids: [],
        };
        let regions = [...this.state.regions, selectedRegion];
        this.setState({
          ...this.state,
          selectionStart: null,
          selectionEnd: null,
          regions,
          selectedRegion,
        });
      });
    }
  };

  findDatetime = (position) => {
    const { subOffsets, startDatetimes, buffer } = this.props;
    for (var i = 0; i < subOffsets.length; ++i) {
      if (subOffsets[i + 1] > position * buffer.sampleRate) {
        return new Date(
          startDatetimes[i].getTime() +
            (position - subOffsets[i] / buffer.sampleRate) * 1000
        );
      }
    }
  };

  findOffsetIndex = (position) => {
    const { offsets, buffer } = this.props;
    for (var i = 0; i < offsets.length; ++i) {
      if (offsets[i + 1] > position * buffer.sampleRate) {
        return i;
      }
    }
  };

  findMaxPositionInSameOffset = (position) => {
    const { offsets, buffer } = this.props;
    for (var i = 0; i < offsets.length; ++i) {
      if (offsets[i + 1] > position * buffer.sampleRate) {
        return (offsets[i + 1] - 1) / buffer.sampleRate;
      }
    }
  };
}

const styles = (theme) =>
  createStyles({
    buttonGroup: {
      backgroundColor: theme.custom.palette.secondary,
      color: theme.custom.palette.primary["100"],
      boxShadow: theme.custom.boxShadow["2"],
    },
    button: {
      background: theme.custom.palette.gradient.primary.default,
      color: theme.custom.palette.secondary,
    },
    iconButton: {
      color: theme.custom.palette.primary["100"],
      background: theme.custom.palette.secondary,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0.7, 1),
      boxShadow: theme.custom.boxShadow["2"],
    },
    loop: {
      background: theme.custom.palette.gradient.primary.default,
      color: theme.custom.palette.secondary,
    },
  });

const StyledComponent = withStyles(styles)(PlayerComponent);

export default connect(
  (state) => ({
    analysisTimeManagerData: state.machineDetail.analysisTimeManager.data,
  }),
  (dispatch) => ({
    dispatch,
    labelProcessing,
    setTime: actions.setTime,
  })
)(StyledComponent);
