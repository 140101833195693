import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { prepareActions } from "store/helpers";
import { EActions, IActionTypes } from "./types";
import { AppState } from "store";
import { urlFriendlyTZ } from "shared/helpers";
import { getAvailableDates } from "api/handlers/dataLabeling/getAvailableDates";
import { getPlacements } from "api/handlers/dataLabeling/getPlacements";
import { getAvailableRegions } from "api/handlers/dataLabeling/getAvailableRegions";
import { getAvailableLabels } from "api/handlers/dataLabeling/getAvailableLabels";
import { getPlacementTypes } from "api/handlers/dataLabeling/getPlacementTypes";
import { getAudio } from "api/handlers/dataLabeling/getAudio";
import { getAudioRegions } from "api/handlers/dataLabeling/getAudioRegions";
import moment from "moment";
import { getDatetimeTimestamp } from "components/dataLabeling/helpers";
import mapValues from "lodash/mapValues";
import keyBy from "lodash/keyBy";
import uniq from "lodash/uniq";
import { getLabelCategories } from "api/handlers/dataLabeling/getLabelCategories";
import { DATE_FORMAT, TIME_FORMAT } from "components/dataLabeling/constants";
export const path = "machineDetail/sounds";

const actionData = [
  [EActions.setCommonData, "data", "cb"],
  [EActions.setZoom, "zoom", "placement"],
  [EActions.setFFT, "renderFFT"],
  [EActions.setLoadingBuffer, "placement", "loadingBuffer"],
  [EActions.setBufferLoaded, "placement", "buffer", "rest"],
  [EActions.setDraw, "draw"],
  [EActions.changeTimezone, "data"],
  [EActions.setReset],
  [EActions.setZoomOutLoading, "loading", "placement"],
  [EActions.setBulkZoom, "zoom"],
  [EActions.setPlaying, "placement"],
  [EActions.setLargeDownloadAllowed, "largeDownloadAllowed"],
  [EActions.setVolume, "placement", "volume"],
  [EActions.setLabelsFilterData, "labelsFilterData", "placement"],
  [EActions.setIsPreviousClicked, "isPreviousClicked"],
  [EActions.setIsNextClicked, "isNextClicked"],
  [EActions.setCheckedLabels, "checkedLabels"],
  [EActions.setIsChecked, "isChecked"],
  [EActions.setShowHiddenLabels, "isHiddenLabelShown"],
  [EActions.setIsCombinationOnly, "isCombinationOnly"],
  [EActions.setDateTime, "date", "time"],
];

const actions = prepareActions<IActionTypes, EActions>(actionData, path);

export default actions;

export const fetchStaticData =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const { timezoneOffset } = getState().machineDetail.sounds;
      const machine = getState().machineDetail.machine.machine;
      const [yearData, placements] = await Promise.all([
        getAvailableDates(machine.id, timezoneOffset),
        getPlacements(machine.id),
      ]);

      const allLabels = await getAvailableLabels(machine.id);
      const allLabelsCategoryIds: number[] = uniq(
        allLabels
          .map(({ category }: { category: number }) => category)
          .filter((category: number) => Boolean(category))
      );
      const labelCategories = allLabelsCategoryIds.length
        ? await getLabelCategories(allLabelsCategoryIds)
        : [];

      const placementTypesIds = placements
        ? placements.map((placement: any) => placement.type)
        : [];

      const placementTypes = await getPlacementTypes({
        ids: placementTypesIds,
      });

      dispatch(
        actions.setCommonData({
          yearData,
          placements: mapValues(keyBy(placements, "value")),
          labelPlacements: placements,
          placementTypes,
          allLabels,
          labelCategories,
          machine,
        })
      );
    } catch (err) {}
  };

export const fetchDays =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const { timezoneOffset } = getState().machineDetail.sounds;
      const machine = getState().machineDetail.machine.machine;
      const yearData = await getAvailableDates(machine.id, timezoneOffset);

      dispatch(
        actions.setCommonData({
          yearData,
        })
      );
    } catch (err) {}
  };

let currentAbortController: any = null;

export const fetchCommonData =
  ({ tz, time, date, duration }: any, cb: any) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const { machine, labelPlacements, yearData } =
        getState().machineDetail.sounds;

      const dDate = date ? date : Object.keys(yearData).slice(-1)[0];

      const dayData = await getAvailableRegions(
        machine.id,
        dDate,
        tz,
        labelPlacements
      );

      if (!time && dDate) {
        if (!time && dayData.length) {
          time = moment.unix(dayData.slice(-1)[0].start).format("HH:mm");
          if (dDate && time) {
            return cb({
              timezoneOffset: urlFriendlyTZ(true, tz),
              date: dDate,
              time,
              duration,
            });
          }
        }
      }

      dispatch(
        actions.setCommonData({
          dayData,
          date: dDate,
          time,
          timezoneOffset: tz,
          machine,
          duration,
        })
      );
      for (let i of labelPlacements) {
        dispatch(
          fetchSound({
            timezoneOffset: tz,
            time,
            date: dDate,
            placement: i.value,
            duration,
          })
        );
      }
    } catch (err) {}
  };

export const fetchSound =
  ({
    timezoneOffset,
    time,
    date,
    placement,
    duration,
    beforeLabel,
    afterLabel,
  }: any) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const {
        machine: { id },
        dayData,
        sampleRate,
        loadingCommonData,
      } = getState().machineDetail.sounds;

      if (
        id &&
        date &&
        dayData &&
        time &&
        placement &&
        duration &&
        timezoneOffset &&
        !loadingCommonData
      ) {
        if (currentAbortController) {
          currentAbortController.abort();
        }

        currentAbortController = new AbortController();
        const { signal } = currentAbortController;

        dispatch(actions.setLoadingBuffer(placement, true));
        let start = getDatetimeTimestamp(`${date} ${time}`);
        let end = start + duration;
        if (start) {
          try {
            const [buffer, regions] = await Promise.all([
              getAudio(
                placement,
                start,
                Number(duration),
                timezoneOffset,
                sampleRate,
                signal
              ),
              getAudioRegions(
                id,
                start,
                start + 3600,
                placement,
                timezoneOffset
              ),
            ]);

            const regionData = await getAudioRegions(
              id,
              start,
              start + Number(duration),
              placement,
              timezoneOffset,
              beforeLabel,
              afterLabel
            );

            const labelsData = regionData?.map((item: any) => {
              const region = regions?.find(
                (region: any) => region.ids[0] === item.ids[0]
              );
              return {
                ...item,
                labels: region.labels,
              };
            });
            const data = labelsData.sort((a: any, b: any) => a.start - b.start);

            dispatch(actions.setLabelsFilterData(data, placement));
            dispatch(
              actions.setBufferLoaded(placement, {
                buffer,
                regions,
                bufferStart: start,
                bufferEnd: end,
              })
            );
          } catch (err: any) {
            if (err.name !== "AbortError") {
              dispatch(actions.setLoadingBuffer(placement, false));
            }
          }
        }
      }
    } catch (err) {
      dispatch(actions.setLoadingBuffer(placement, false));
    }
  };

export const loadBufferSilent =
  (
    windowStart: number,
    offset: number,
    beforeLabel?: number | boolean,
    afterLabel?: number | boolean
  ) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    const {
      machine: { id },
      duration,
      timezoneOffset,
      sampleRate,
      labelPlacements,
    } = getState().machineDetail.sounds;

    currentAbortController = new AbortController();
    const { signal } = currentAbortController;

    const start = windowStart + offset;
    for (let i of labelPlacements) {
      dispatch(actions.setZoomOutLoading(true, i.value));
      const data = await getAudioRegions(
        id,
        start,
        start + Number(duration),
        i.value,
        timezoneOffset,
        beforeLabel,
        afterLabel
      );
      const labelsData = data?.sort((a: any, b: any) => a.start - b.start);

      const firstItem = labelsData?.[0];
      const lastItem = labelsData?.[labelsData?.length - 1];

      const startDate =
        beforeLabel && labelsData?.length
          ? lastItem?.start - duration
          : afterLabel && labelsData?.length
            ? firstItem?.start
            : start;

      const endDate = startDate + duration;
      const labels =
        labelsData && labelsData.length
          ? labelsData?.filter(
              (item: any) => item.start >= startDate && item.start <= endDate
            )
          : [];

      Promise.all([
        getAudio(
          i.value,
          startDate,
          duration,
          timezoneOffset,
          sampleRate,
          signal
        ),
        // device, start, start + 1h, channel, tz
        getAudioRegions(
          id,
          windowStart,
          windowStart + 3600,
          i.value,
          timezoneOffset,
          beforeLabel,
          afterLabel
        ),
      ]).then(([buffer, regions]) => {
        // loading wave & labels
        const labelsData = labels?.map((item: any) => {
          const region = regions?.find(
            (region: any) => region.ids[0] === item.ids[0]
          );
          return {
            ...item,
            labels: region.labels,
          };
        });
        dispatch(actions.setLabelsFilterData(labelsData, i.value));
        dispatch(
          actions.setBufferLoaded(
            i.value,
            {
              buffer,
              regions,
              bufferStart: startDate,
              bufferEnd: endDate,
            },
            {
              zoomOutLoading: false,
            }
          )
        );
      });
    }
  };

export const loadPlacementBufferSilent =
  (
    windowStart: number,
    offset: number,
    placement: number,
    beforeLabel?: number | boolean,
    afterLabel?: number | boolean,
    setQuery?: any
  ) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    const {
      machine: { id },
      duration,
      timezoneOffset,
      sampleRate,
    } = getState().machineDetail.sounds;

    currentAbortController = new AbortController();
    const { signal } = currentAbortController;

    const start = windowStart + offset;
    dispatch(actions.setZoomOutLoading(true, placement));
    const data = await getAudioRegions(
      id,
      start,
      start + Number(duration),
      placement,
      timezoneOffset,
      beforeLabel,
      afterLabel
    );
    const labelsData = data?.sort((a: any, b: any) => a.start - b.start);

    const firstItem = labelsData?.[0];
    const lastItem = labelsData?.[labelsData?.length - 1];

    const startDate =
      beforeLabel && labelsData?.length
        ? lastItem?.end - duration
        : afterLabel && labelsData?.length
          ? firstItem?.start
          : start;

    const date = moment.unix(startDate).format(DATE_FORMAT);
    const time = moment.unix(startDate).format(TIME_FORMAT);

    setQuery({
      timezoneOffset: urlFriendlyTZ(true, timezoneOffset),
      date,
      time,
      duration,
    });

    const endDate = startDate + duration;
    const labels =
      labelsData && labelsData.length
        ? labelsData?.filter(
            (item: any) =>
              (item.start >= startDate || item.end >= startDate) &&
              item.start <= endDate
          )
        : [];

    Promise.all([
      getAudio(
        placement,
        startDate,
        duration,
        timezoneOffset,
        sampleRate,
        signal
      ),
      // device, start, start + 1h, channel, tz
      getAudioRegions(
        id,
        windowStart,
        windowStart + 3600,
        placement,
        timezoneOffset,
        beforeLabel,
        afterLabel
      ),
    ]).then(([buffer, regions]) => {
      // loading wave & labels
      const labelsData = labels?.map((item: any) => {
        const region = regions?.find(
          (region: any) => region.ids[0] === item.ids[0]
        );
        return {
          ...item,
          labels: region.labels,
        };
      });
      dispatch(actions.setLabelsFilterData(labelsData, placement));
      dispatch(
        actions.setBufferLoaded(
          placement,
          {
            buffer,
            regions,
            bufferStart: startDate,
            bufferEnd: endDate,
          },
          {
            zoomOutLoading: false,
          }
        )
      );
    });
  };

export const changeTimezone =
  (newDate: string, newTime: string, tz: string) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    const { duration, labelPlacements } = getState().machineDetail.sounds;
    const start = getDatetimeTimestamp(newDate + " " + newTime);
    const buffers: any = {};

    if (start) {
      for (let i of labelPlacements) {
        buffers[i.value] = {
          buffer: {
            bufferStart: getDatetimeTimestamp(newDate + " " + newTime),
            bufferEnd: getDatetimeTimestamp(newDate + " " + newTime) + duration,
          },
        };
      }
      dispatch(
        actions.changeTimezone({
          timezoneOffset: tz,
          date: newDate,
          time: newTime,
          buffers,
          bufferStart: getDatetimeTimestamp(newDate + " " + newTime),
        })
      );
    }
  };
