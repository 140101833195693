import { Box, Button } from "@mui/material";
import Checkbox from "components/checkbox";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ActionButton from "components/button";
import { isEqual, orderBy, uniqBy } from "lodash";
import { useDispatch } from "react-redux";
import actions, {
  loadPlacementBufferSilent,
} from "store/machineDetail/sounds/actions";
import { useSelector } from "react-redux";
import { AppState } from "store";
import Heading4 from "components/typography/heading/heading4";
import Divider from "components/typography/heading/divider";

const {
  setIsPreviousClicked,
  setIsNextClicked,
  setCheckedLabels,
  setIsChecked,
  setShowHiddenLabels,
  setZoomOutLoading,
  setIsCombinationOnly,
} = actions;
const LabelSwitch = ({
  labelsData,
  onSelectRegion,
  selectedRegion,
  play,
  allLabels,
  labels,
  duration,
  placement,
  windowStart,
  windowEnd,
  regions,
  updateRegion,
  setQuery,
}: any) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const {
    isPreviousClicked,
    isNextClicked,
    checkedLabels,
    isChecked,
    isHiddenLabelShown,
    isCombinationOnly,
  } = useSelector((state: AppState) => state.machineDetail.sounds, isEqual);
  const onPreviousClick = (e: any) => {
    if (labelsData?.length) {
      if (selectedRegion) {
        const selectedIndex = labelsData.findIndex(
          (label: any) => label.start === selectedRegion.start
        );

        if (selectedIndex) {
          const region = labelsData[selectedIndex - 1];
          onSelectRegion(region, e);
          dispatch(setIsPreviousClicked(false));
        } else {
          dispatch(setIsPreviousClicked(true));
          const start = selectedRegion.start - duration;
          dispatch(
            loadPlacementBufferSilent(
              start,
              0,
              placement.value,
              selectedRegion.ids[0],
              undefined,
              setQuery
            )
          );
        }
      } else {
        onSelectRegion(labelsData?.[0], e);
      }
    } else {
      dispatch(setIsPreviousClicked(true));
      const start = windowStart - duration;
      dispatch(
        loadPlacementBufferSilent(
          start,
          0,
          placement.value,
          true,
          undefined,
          setQuery
        )
      );
    }
  };

  const onNextClick = (e: any) => {
    if (labelsData?.length) {
      if (selectedRegion) {
        const selectedIndex = labelsData.findIndex(
          (label: any) => label.start === selectedRegion.start
        );

        if (selectedIndex !== labelsData.length - 1) {
          const region = labelsData[selectedIndex + 1];
          onSelectRegion(region, e);
          dispatch(setIsNextClicked(false));
        } else {
          const start = selectedRegion.start;
          dispatch(setIsNextClicked(true));
          dispatch(
            loadPlacementBufferSilent(
              start,
              0,
              placement.value,
              undefined,
              selectedRegion.ids[0],
              setQuery
            ) as any
          );
        }
      } else {
        onSelectRegion(labelsData?.[0], e);
      }
    } else {
      dispatch(setIsNextClicked(true));
      dispatch(
        loadPlacementBufferSilent(
          windowEnd,
          0,
          placement.value,
          undefined,
          true,
          setQuery
        ) as any
      );
    }
  };

  useEffect(() => {
    if (labelsData) {
      if (isPreviousClicked) {
        onSelectRegion(labelsData?.[labelsData?.length - 1]);
      }
      if (isNextClicked) {
        onSelectRegion(labelsData?.[0]);
      }
    }
  }, [labelsData, isPreviousClicked, isNextClicked]);

  const processLabels = () => {
    let selected: any = {};
    for (let label of labels) {
      selected[label.name] = true;
    }
    let newLabels = [];
    newLabels = uniqBy([...labels, ...allLabels], (item) => item.name);
    newLabels = orderBy(newLabels, ["name"], ["asc"]);
    return { newLabels, selected };
  };

  const { newLabels: data } = processLabels();

  const modifiedData = data?.filter(({ name }: any) => name?.[0] !== "$");

  useEffect(() => {
    if (isCombinationOnly) {
      const filteredRegions = regions.filter((item: any) => {
        const isWithinRange =
          item.start >= windowStart && item.end <= windowEnd;

        if (!isWithinRange) {
          return true;
        }
        const matchingLabels = item.labels.filter((label: any) =>
          checkedLabels.some((keepLabel: string) => keepLabel === label.name)
        );

        if (checkedLabels.length > 1) {
          return matchingLabels.length > 1;
        } else {
          return matchingLabels.length === item.labels.length;
        }
      });
      updateRegion(filteredRegions);
    } else {
      if (isChecked) {
        const filteredRegions = regions.filter((item: any) => {
          const isWithinRange =
            item.start >= windowStart && item.end <= windowEnd;

          if (!isWithinRange) {
            return true;
          }
          const hasMatchingLabel = item.labels.some((label: any) =>
            checkedLabels.some((keepLabel: any) => keepLabel === label.name)
          );

          return hasMatchingLabel;
        });
        updateRegion(filteredRegions);
      }
    }
  }, [checkedLabels, isChecked, isCombinationOnly]);

  return (
    <Box maxWidth="17rem" pl={1}>
      <Heading4 pl={1}>{t("filterLabel.title")}</Heading4>
      <Box
        sx={{
          maxHeight: "21rem",
          overflowY: "scroll",
          overflowX: "auto",
          borderWidth: 1,
          borderColor: "#bdbdbd",
          borderStyle: "solid",
        }}
        pt={1}
        pr={1}
      >
        <Box>
          <Checkbox
            label={t("filterLabel.combination")}
            onChange={(e) => {
              dispatch(setZoomOutLoading(true, placement.value));
              dispatch(setIsCombinationOnly(!isCombinationOnly));
              setTimeout(() => {
                dispatch(setZoomOutLoading(false, placement.value));
              }, 1000);
            }}
            checked={isCombinationOnly}
            fontWeight="normal"
          />
          <Divider line noMargin />
        </Box>

        {modifiedData.map((item: any) => {
          const name = item.name;
          const isChecked = checkedLabels.some(
            (label: string) => label === name
          );
          return (
            <Box>
              <Checkbox
                label={name}
                onChange={(e) => {
                  dispatch(setZoomOutLoading(true, placement.value));
                  dispatch(setIsChecked(true));
                  if (isChecked) {
                    dispatch(
                      setCheckedLabels(
                        checkedLabels.filter((label: string) => label !== name)
                      )
                    );
                  } else {
                    dispatch(setCheckedLabels([...checkedLabels, name]));
                  }

                  setTimeout(() => {
                    dispatch(setZoomOutLoading(false, placement.value));
                  }, 1000);
                }}
                checked={isChecked}
              />
            </Box>
          );
        })}
        <Checkbox
          label={t("filterLabel.showHidden")}
          onChange={(e) => {
            dispatch(setShowHiddenLabels(!isHiddenLabelShown));
          }}
          checked={isHiddenLabelShown}
          fontWeight="normal"
        />
        {isHiddenLabelShown
          ? data
              .filter(({ name }: any) => name?.[0] === "$")
              .map((item: any) => {
                const name = item.name;
                const isChecked = checkedLabels.some(
                  (label: string) => label === name
                );
                return (
                  <Box>
                    <Checkbox
                      label={name && name?.[0] === "$" ? name.slice(1) : name}
                      onChange={(e) => {
                        dispatch(setZoomOutLoading(true, placement.value));
                        dispatch(setIsChecked(true));
                        if (isChecked) {
                          dispatch(
                            setCheckedLabels(
                              checkedLabels.filter(
                                (label: string) => label !== name
                              )
                            )
                          );
                        } else {
                          dispatch(setCheckedLabels([...checkedLabels, name]));
                        }

                        setTimeout(() => {
                          dispatch(setZoomOutLoading(false, placement.value));
                        }, 1000);
                      }}
                      checked={isChecked}
                    />
                  </Box>
                );
              })
          : null}
        <Box display="flex">
          <ActionButton
            onClick={() => {
              const newData = isHiddenLabelShown
                ? [...modifiedData, ...data]
                : modifiedData;
              dispatch(setCheckedLabels(newData.map((item: any) => item.name)));
            }}
          >
            {t("filterLabel.selectAll")}
          </ActionButton>
          <ActionButton onClick={() => dispatch(setCheckedLabels([]))}>
            {t("filterLabel.deselectAll")}
          </ActionButton>
        </Box>
      </Box>
      <Box mb={1} mt={2.5} display="flex" gap={1} flexWrap="wrap">
        <Button
          style={{ maxWidth: "5rem", lineHeight: 1 }}
          variant="contained"
          onClick={onPreviousClick}
        >
          <span>{t("taggingapp.button.filterLabel.previous")}</span>
        </Button>
        <Button
          variant="contained"
          onClick={onNextClick}
          style={{ maxWidth: "5rem", lineHeight: 1 }}
        >
          <span>{t("taggingapp.button.filterLabel.next")}</span>
        </Button>
        <Button
          data-cy="playButton"
          variant="contained"
          onClick={() => {
            if (selectedRegion) {
              play();
            }
          }}
          style={{ maxWidth: "5rem", lineHeight: 1 }}
        >
          <span>{t("taggingapp.button.filterLabel.play")}</span>
        </Button>
      </Box>
    </Box>
  );
};

export default LabelSwitch;
